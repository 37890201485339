import React from 'react';
import { render } from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import App from './components/App';
import './styles/main_styles.css';

const reducer = (state, action) => {
    const { type } = action;
    switch (action.type) {
        case "GET_PICS_SUCCESS":
            return {
                ...state,
                pics: action?.payload
            }
            break;
        default:
            return state;
    }
};

const store = createStore(reducer);

document.title = 'Bomb Shelters Art';

window.myServer = 'https://shelters-art.com/';

render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
)
