import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from './Header';
import Home from './Home';
import About from './About';
import Gallery from './Gallery';
import GalleryItem from './GalleryItem';
import loadingImg from '../images/loading2.gif';
import { browserHistory } from "react-router";
import {
	BrowserRouter,
	Switch,
	Route,
} from "react-router-dom";


class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			contentLoaded: false
		}
	}

	componentDidMount() {
		this.setState({ contentLoaded: true });
	}

	render() {
		return (
			<BrowserRouter history={browserHistory }>
				<div>
					<Header location={window.location} />
					{
						this.state.contentLoaded ? (
							<div className="page-container">
								<Switch>
									<Route path="/about" component={About} />
									<Route exact path="/gallery" component={Gallery} />
									<Route path="/gallery/item/:id" component={GalleryItem} />
									<Route exact path="/" component={Home} />
								</Switch>
							</div>
						) : <img src={loadingImg} />
					}
				</div>
			</BrowserRouter>
		)
	}
}

const mapStateToProps = state => {};
const mapDispatchToProps = dispatch => {};
export default connect(mapStateToProps, mapDispatchToProps)(App)