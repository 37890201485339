import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from "react-router-dom";

class Gallery extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pics: null
        };
    }
    componentDidMount() {
        fetch(window.myServer + 'ajax.php?method=get_pics', { headers: { 'Content-Type': 'application/json' } })
            .then(res => res.json())
            .then(
                (result) => {
                    window.pics = result;
                    this.setState({pics: result});
                }
            );
    }

    render() {
        return (
            <div className="gallery-container">
                {
                    this.state?.pics?.map((item) => {
                        return (
                            <div
                                key={item.id}
                                className="gallery-item"
                                style={{backgroundImage: `url(${window.myServer}${item.img_1.replace('uploads/', 'uploads/d_')})`}}
                                onClick={() => {
                                    window.selectedImg = item;
                                    this.props.history.push(`/gallery/item/${item.id}`);
                                }}
                            />
                        );
                    })
                }
            </div>
        );
    }
}

const mapStateToProps = state => {};

const mapDispatchToProps = dispatch => {};
export default connect(mapStateToProps, mapDispatchToProps)(Gallery);
