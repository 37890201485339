import React, { Component } from 'react';
import Nav from "components/Nav";
import Logo from '../images/logo_new.png';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {}

    render() {
        return (
            <>
                <div className="header-banner">
                    <div className="header-content">
                        <img src={Logo} />
                    </div>
                </div>
                <Nav location={this.props?.location}  />
            </>
        );
    }
}

export default Header;
