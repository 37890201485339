import React, { Component } from 'react';
import ladder from '../images/ladder.png';
import paint from '../images/paint.png';
import about1 from '../images/about_1.jpg';

class About extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }
    componentDidMount() {}

    render() {
        return (
            <>
                <img src={ladder} className="ladder" />
                <img src={paint} className="paint" />
                <div className="main-title">
                    In Israel, artsy bomb shelters bring comfort, beauty to kids in crisis
                </div>
                <img className="about-img" src={about1} />
                <div className="story-section">
                    <iframe src="https://www.youtube.com/embed/oWiUC2-g_cs"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                    <div>
                        <div>
                            Learn about JNF’s life-saving work for the 50,000 Israelis living alongside the Israel-Gaza border. Watch bomb shelters turn into works of art and see how YOU can make a difference for the Israelis who live in this region.
                        </div>
                        <div>
                            More from Jewish National Fund:
                        </div>
                        <div>
                            Website - <a href="https://www.youtube.com/redirect?event=video_description&amp;redir_token=QUFFLUhqazR3bXA0OGtfbGxub0FJWnM5LWU2S2hMV28yd3xBQ3Jtc0ttTGZLT1g2bVZCV3o5V3djMXBYTjBjVmVkOFlZNVNIR0N3QTIzLS1BczU5NllFZmxrTDE3ZXJxY0w1TmVsVXpSYkp3X1c5UUxwbGgyblhHbDhwc1FneERsNS1CQjhDX0FqbjVldFM1TnJacWlUOHNoSQ&amp;q=https%3A%2F%2Fjnf.org"
                                         rel="nofollow" target="_blank" dir="auto">https://jnf.org</a>
                        </div>
                    </div>
                </div>
                <div className="story-section">
                    <div className="story-section-title">Contact us</div>
                    <div className="contact-row">
                        <span>
                            Address:
                        </span>
                        <span>
                            Shokeda
                        </span>
                    </div>
                    <div className="contact-row">
                        <span>
                            Email:
                        </span>
                        <span>
                            yetzira10@gmail.com
                        </span>
                    </div>
                    <div className="contact-row">
                        <span>
                            Phone:
                        </span>
                        <span>
                            0500000000
                        </span>
                    </div>
                </div>
            </>
        );
    }
}

export default About;
