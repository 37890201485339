import React, { Component } from 'react';
import loadingImg from '../images/loading2.gif';

class GalleryItem extends Component {
    constructor(props) {
        super(props);
        this.state = { pic: window.selectedImg, loaded: false}
    }

    componentDidMount() {
        if (!window.selectedImg) {
            fetch(window.myServer + 'ajax.php?method=get_pics&id=' + this.props.match.params.id, { headers: { 'Content-Type': 'application/json' } })
                .then(res => res.json())
                .then(
                    (result) => {
                        window.selectedImg = result?.[0];
                        this.setState({pic: result?.[0]});
                    }
                );
        }
    }

    render() {
        const { loaded } = this.state;
        return (
            <>
                <img
                    className={`about-img${loaded ? '' : ' hidden'}`}
                    src={window.myServer + this.state.pic?.img_1}
                    onLoad={ () => this.setState({ loaded: true })}
                />
                <img
                    className={loaded ? 'hidden' : 'loading'}
                    src={window.myServer + loadingImg}
                />
                <div className="gallery-details-section">
                    <div className="flex_1">
                        <div>
                            <span className="bold italic grey">"{this.state.pic?.name}"</span>
                        </div>
                        <div className="margin_t_10">
                            <span className="bold">Price: </span>
                            <span>${this.state.pic?.price}</span>
                        </div>
                        <div className="margin_t_10">
                            <span className="bold">Size (cm): </span>
                            <span>{this.state.pic?.size} </span>
                        </div>
                    </div>
                    <div className="flex_fixed">
                        <input type="button" value="Buy" />
                    </div>
                </div>
            </>
        );
    }
}

export default GalleryItem;
