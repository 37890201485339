import { NavLink } from "react-router-dom";
import React, {Component} from "react";

class Nav extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }
    componentDidMount() {}

    render() {
        return (
            <nav>
                <div className="nav-items">
                    <div className="nav-item">
                        <NavLink activeClassName="selected" className="nav-item" exact to="/">Home</NavLink>
                    </div>
                    <div className="nav-item">
                        <NavLink  activeClassName="selected" to="/gallery">Gallery</NavLink>
                    </div>
                    <div className="nav-item">
                        <NavLink activeClassName="selected" exact to="/about">About</NavLink>
                    </div>
                </div>
            </nav>
        );
    }
}

export default Nav;
