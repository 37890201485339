import React, { Component } from 'react';
import ladder from '../images/ladder.png';
import paint from '../images/paint.png';
import main from '../images/main_2.jpg';
import mainPic from '../images/main_2_pic.jpg';
import mainSallon from '../images/main_2_sallon.jpg';
import story1 from '../images/main_1.jpg';
import loadingImg from '../images/loading2.gif';

class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			img1: null,
			img2: null,
			img3: null
		};
	}

	componentDidMount() {
		const img1 = new Image();
		img1.onLoad = () => {
			this.setState({img1});
		}
		const img2 = new Image();
		img2.onLoad = () => {
			this.setState({img2});
		}
		const img3 = new Image();
		img3.onLoad = () => {
			this.setState({img3});
		}

		if (img1.complete) {
			this.setState({img1});
		}
		if (img2.complete) {
			this.setState({img2});
		}
		if (img3.complete) {
			this.setState({img3});
		}
		
		img1.src = main;
		img2.src = mainPic;
		img3.src = mainSallon;
	}

	render() {
		const { animClass } = this.state;
		return (
			<>
				<img src={ladder} className="ladder" />
				<img src={paint} className="paint" />
				<div className="main-title">
					Brushing Away Terror One Mural at a Time
				</div>
				<div className="main-sub-title">
					Israeli Street Artist’s Unique Canvas Transforms Bomb Shelters into Dazzling Oasis, in Israel, artsy bomb shelters bring comfort, beauty to kids in crisis
				</div>
				<div className="home-img">
					{
						(this.state.img1 && this.state.img2 && this.state.img3) ? <>
							<img src={this.state.img1.src} className="anim main-real"/>
							<img src={this.state.img2.src} className="anim main-pic"/>
							<img src={this.state.img3.src} className="anim main-sallon"/>
						</> : <img src={loadingImg} className="loading"/>
					}
				</div>
				<div className="story-section">
					<img src={story1} />
					<div>
						It’s not uncommon to see children and families posing for photos in front of the bright, colorful murals in Wynwood, Miami...but how about in the Gaza Envelope? Responding to acts of terror, Israeli artist, Elisaf Miara made it his mission to beautify the structures that constantly remind Israeli’s in the Gaza region of the dangers lurking just around the corner; creating a calming oasis for families when they need to escape.
					</div>
				</div>
				<div className="story-section">
					<iframe src="https://www.youtube.com/embed/e8MKcaaEbmo"
							title="YouTube video player" frameBorder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen></iframe>
					<div>
						This week we finished painting another bomb shelter, part of #JNF’s ‘Bomb Shelter Painting Project. We take scary and ugly looking concrete structures and turn them into beautiful pieces of art that, in an emergency, the children won’t be scared to enter.
						Watch this space to hear about our upcoming art tours from Tel Aviv to the Gaza Envelope.
						Shabbat Shalom. I think of you and pray for your well-being
					</div>
				</div>
			</>
		);
	}
}

export default Home;
